<template>
    <div class="container">
        <div class="header">
            <div :style="ClientWidth_number > 850 ? {'width':'60.7vw'} : ''" class="header-box">
                <div @click="()=>{this.$router.push('/home')}" style="display: flex; align-items: center">
                    <img alt="" src="../assets/logo.png" style="margin-right: 10px"/>
                    <div>赛尔安全自服务平台</div>
                </div>
                <div>
                  <i :class="toggle_menu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
                     @click="toggle_menu = !toggle_menu"
                     v-if="ClientWidth_number < 850">
                  </i>
                </div>
            </div>
        </div>
        <div class="container-menu" v-if="ClientWidth_number > 850">
            <div class="menu-box">
                <li :key="index"
                    :style="(menu_select_index === index || menu_mouse_hover_index === index) ? { color: '#ccc', background: 'rgba(204,204,204,0.1)'} : ''"
                    @click="skip(index)"
                    @mouseover="mouseOver(index)"
                    v-for="(item, index) in title_list">
                    {{ item }}
                </li>
            </div>
        </div>
        <div class="phone_menu-box" v-else>
            <div v-if="toggle_menu">
                <li :key="index"
                    :style="menu_select_index === index ? { color: '#fff', background: 'skyblue' } : ''"
                    @click="skip(index)"
                    v-for="(item, index) in title_list">
                    {{ item }}
                </li>
            </div>
        </div>
        <div class="container-body">
            <router-view/>
        </div>
        <div class="phone_body">
        </div>
        <!-- <div style="flex: 1"></div> -->
        <footer class="footer text-center">
            <p>京ICP备09064735号-1 {{white_space}} 联系电话: 010-88571122</p>
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
              white_space: '\u3000\u3000\u3000\u3000',
              ClientWidth_number: document.documentElement.clientWidth,
              toggle_menu: false,
              menu_select_index:-1,
              menu_mouse_hover_index:-1,
              title_list: [
                    "首页",
                    '安全检测',
                    '威胁管控',
                    "安全咨询",
                    "安全防护",
                    "安全众测",
                    "资产管理",
                    "实战演习",
                    "关于我们",
              ],
            };
        },
        created() {
            this.getClientWidth();
            this.menu_select_index = 0;
        },
        methods: {
            skip(index) {
                this.menu_select_index = index;

                this.toggle_menu = false;

                switch (index) {
                    case 0 :
                        this.$router.push('/home');
                        break;
                    case 1 :
                        this.$router.push('/vss.html');
                        break;
                    case 2 :
                        this.$router.push('/ad.html');
                        break;
                    case 3 :
                        this.$router.push('/consult.html');
                        break;
                    case 4 :
                        this.$router.push('/protect.html');
                        break;
                    case 5 :
                        this.$router.push('/test.html');
                        break;
                    case 6 :
                        this.$router.push('/management.html');
                        break;
                    case 7 :
                        this.$router.push('/practice.html');
                        break;
                    case 8 :
                        this.$router.push('/about.html');
                        break;
                }
            },

            getClientWidth() {
                addEventListener('resize', () => {
                    this.$store.dispatch('set_ClientWidth', document.documentElement.clientWidth);
                    this.ClientWidth_number = document.documentElement.clientWidth
                });
                window.onhashchange = () => {
                    this.toggle_menu = false;
                }
            },

            mouseOver(index){
              this.menu_mouse_hover_index = index;
            },
        },
    };
</script>

<style lang='less' scoped>
    @baseWidth: 1980;
    .pxfix(@w) {
        width: (@w / @baseWidth) * 100vw;
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .container {
        .pxfix(1980);
        min-height: 100vh;
        background: #ccc;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .phone_menu-box {
            .pxfix(1700);
            background: #fff;
            margin: 0 auto;
            box-shadow: 10px 10px 5px #888888;

            li {
              font-size: 12px;
              border: 1px solid #ebebeb;
              padding: 10px 0 10px 10px;
            }
        }

        .header {
            height: 75px;
            background: #fff;

            .header-box {
                .pxfix(1700);
                height: 75px;
                margin: 0 auto;
                display: flex;
                color: #4b77be;
                text-decoration: none;
                font-weight: bolder;
                font-size: 1.3rem;
                justify-content: space-between;
                align-items: center;
            }
        }

        .container-menu {
            .pxfix(1980);
            height: 51px;
            background: #333;
            display: flex;
            justify-content: center;

            .menu-box {
                min-width: 60.7vw;
                height: 51px;
                display: flex;
                align-items: center;

                li {
                    margin-left: 10px;
                    font-size: 0.8rem;
                    font-weight: bolder;
                    height: 51px;
                    padding: 0 10px;
                    line-height: 51px;
                    color: gray;
                    cursor: pointer;
                }
            }
        }

        .phone_body {
            .pxfix(1980);
            position: relative;
            flex: 1;
            margin: 0 auto;
            background: #eff3f8;


        }

        .container-body {
            .pxfix(1980);
            min-height: 700px;
            margin: 0 auto;
            background: #eff3f8;
            display: flex;
            flex: 1;

            .title {
                font-size: 1.2rem;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-weight: normal;
                font-family: "microsoft yahei";
                color: #555;
            }

            .content {
                margin: 0 50px;
                color: #555;
                font-size: 14px;
            }

            .body-box {
                .pxfix(1500);
                margin: 50px auto;
                background: #fff;
            }
        }

        .footer {
            line-height: 50px;
            background: #2f3034;
            width: 100%;
            font-size: 12px;
            color: #fff;
            text-align: center;
        }
    }
</style>
